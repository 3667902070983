body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.lazy-load-image-background {
  display: block !important;
}



@media (min-width: 992px) {
  .ant-drawer-content-wrapper {
      height:0px !important;
      transition: 0s !important;
  }
  body {
      overflow: auto !important;
    }
}

.ant-drawer-content-wrapper{
  height: 100% !important;
}
.ant-dropdown-menu {
  border-radius: 0px !important;
  box-shadow: none !important;
}
.ant-spin-dot-item{
  background-color: #ffffff !important
}

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}
:where(.css-dev-only-do-not-override-17a39f8).ant-btn-primary {
  color: #fff;
  background-color: #1677ff !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-upload-list-item-error {
  border:1px solid #1677ff !important;
}
.ant-input-search-button{
  background: #1677ff !important
}
.ant-btn-primary{
  background: #1677ff !important
}
.ant-menu-horizontal {
  border-bottom:none !important
}
/* Khi ở chế độ điện thoại (xs) */
@media (max-width: 575px) {
  .ant-statistic-content {
    font-size: 12px !important; /* Điều chỉnh kích thước font cho xs */
  }
  
}
/* Khi ở chế độ màn hình lớn (lg) */
@media (min-width: 992px) {
  .ant-statistic-content {
    font-size: 25px !important; /* Điều chỉnh kích thước font cho lg */
  }
}

.ant-statistic-content{
  font-size:16px !important;
}


@tailwind base;
@tailwind components;
@tailwind utilities;